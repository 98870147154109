import * as React from 'react'
import { PageProps, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 50px;

  ${media.lg.min} {
    padding-top: 150px;
    padding-bottom: 50px;
  }
`

const NotFoundPage: React.FC<PageProps> = () => {
  const { lg } = useBreakpoint()

  return (
    <Layout>
      <Seo title="Nie znaleziono strony" />

      <StyledContainer>
        <Heading size={lg ? 30 : 26} sizeDiff={0}>
          Nie znaleziono strony
        </Heading>
        <Link to="/">
          Wróć do <b>Strony Głównej</b>
        </Link>
      </StyledContainer>
    </Layout>
  )
}

export default NotFoundPage
